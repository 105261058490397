body {
  margin:0;
  padding:0;
  font-family: sans-serif;
  background: linear-gradient(#000000, #ff7221) fixed;
  overflow-y: scroll;
}
@font-face {
  font-family: 'CODE';
  src: url('./assets/fonts/CODE\ Bold.otf');
}