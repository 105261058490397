.Modal {
    z-index: 101;
    padding: 0;
    margin: 0;
    top: 0; bottom: 0; left: 0; right: 0;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    /* min-width : 100vw; 
    min-height : 100vh; */
}
.Modal > div {
    padding-top: 45px; /* AVOID CONTACT WITH CLOSE BUTTON */
}

/* HIDDENPOSITION */
/* CENTER  (fade in)*/
.Modal.visible {
    animation: fadeInFromNone 0.2s ease-out;
    display: block;
    opacity: 1;
}
.Modal.hidden {
    display: none;
    opacity: 0;
}

/* RIGHT */
.Modal.visible.right > div {
    animation: slideFromRight 0.2s linear;
    display: block;
}
.Modal.hidden.right > div {
    display: none;
}

/* LEFT */
.Modal.visible.left > div{
    animation: slideFromLeft 0.2s linear;
    display: block;
}
.Modal.hidden.left > div{
    display: none;
}

/* TOP */
.Modal.visible.top > div{
    animation: slideFromTop 0.2s linear;
    display: block;
}
.Modal.hidden.top > div{
    display: none;
}

/* BOTTOM */
.Modal.visible.bottom > div{
    animation: slideFromBottom 0.2s linear;
    display: block;
}
.Modal.hidden.bottom > div{
    display: none;

}

.Modal > div {
    position: absolute;
    top : 5%;
    bottom : 5%;
    left : 5%;
    right: 5%;
    margin: 0 auto;
    border-radius: 10px;
    background-color: black;
}

/* CLOSE BUTTON */

@media only screen and (min-width: 601px) { 
    .Modal > div > button.close {
        position : absolute;
        top : 0;
        right : 0;
    }
}
@media only screen and (max-width: 600px) { 
    .Modal > div > button.close {
        position : fixed;
        top : 5vh;
        right : 5vw;
        width: 50px !important;
    }
}

.Modal > div > button.close {
    color: white;
    width : 50px;
    height : 45px;
    font-size: 1.4em;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0.6;
    cursor:pointer;
}
.Modal > div > button.close:hover {
    opacity: 1;
}
.Modal > div > button.close:before, .Modal > div > button.close:after {
    position: absolute;
    left: 50%;
    bottom: 10%;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: white;
}
.Modal > div > button.close:before {
    transform: rotate(45deg);
}
.Modal > div > button.close:after {
    transform: rotate(-45deg);
}




/* ANIMATIONS */


/* IN center*/
@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
/* IN FROM RIGHT */
@keyframes slideFromRight {
    0% {
        display: none;
        right: 0;
        left: 100vw;
        overflow-x: hidden;
    }

    1% {
        display: block;
        right: 0;
        left: 100vw;
        overflow-x: hidden;
    }

    100% {
        display: block;
        right: 0;
        left: 0;
        overflow-x: none;
    }
}
/* IN FROM RIGHT */
@keyframes slideFromLeft {
    0% {
        display: none;
        right: 100vw;
        left: 0;
        overflow-x: hidden;
    }

    1% {
        display: block;
        right: 100vw;
        left: 0;
        overflow-x: hidden;
    }

    100% {
        display: block;
        right: 0;
        left: 0;
        overflow-x: none;
    }
}
/* IN FROM TOP */
@keyframes slideFromTop {
    0% {
        display: none;
        bottom: 100vh;
        top: 0;
        overflow-y: hidden;
    }

    1% {
        display: block;
        bottom: 100vh;
        top: 0;
        overflow-y: hidden;
    }

    100% {
        display: block;
        bottom: 0;
        top: 0;
        overflow-y: none;
    }
}
/* IN FROM BOTTOM */
@keyframes slideFromBottom {
    0% {
        display: none;
        top: 100vh;
        bottom: 0;
        overflow-y: hidden;
    }

    1% {
        display: block;
        top: 100vh;
        bottom: 0;
        overflow-y: hidden;
    }

    100% {
        display: block;
        top: 0;
        bottom: 0;
        overflow-y: none;
    }
}