ul.Files {
    margin : 0 auto;
    padding : 0;
}
.Files li {
    padding: 15px 65PX 15px 10px;
    margin: 20px 0;
    list-style: none;
    border-bottom: solid 1px white;
    border-top: solid 1px white;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    transition: 0.2s;
    overflow-wrap: break-word;
    cursor: pointer;

}

.Files li:hover {
    background-color: rgba(0,0,0,0.3);
    color:#ff7221;
    border-bottom: solid 1px #ff7221;
    border-top: solid 1px #ff7221;
}

.Files li i {
    display: block;
    font-size : 0.8em;
    color: lightgrey;
    /* margin-left: 15px; */

}

.Files li img {
    position: absolute;
    right : 15px;
    top: 13px;
    width : 28px;
    filter: invert(0.8);
    transition: all 0.15s linear;
}
.Files li:hover img {
    filter : invert(1);
    width: 30px;
    top: 12px;
    right: 14px
}
@media only screen and (min-width: 601px) {
    ul.Files {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
    }
    .Files li {
        max-width : 340px;
        min-width : 340px;
        margin: 10px 5px;
        text-align: center;

    }
}