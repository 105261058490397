.PasswordChange, .ForgottenId {
    width : 300px;
    margin : 0 auto; 
}
.PasswordChange b, .ForgottenId b {
    display : inline-block;
    margin-bottom: 10px;
}
.PasswordChange label, .ForgottenId label  {
    display : inline-block;
    /* height : 600px; */
    position: relative;
    font-size: 0.8em;
}
.PasswordChange input, .ForgottenId input {
    background: none;
    border: none;
    border-bottom: solid 1px orange;
    color : orange;
    margin-bottom: 15px;
}
.PasswordChange button.submit, .ForgottenId button.submit {
    cursor : pointer;
    font-weight: bold;
    width : 100%;
    height : 2em;
    color : orange;
    background-color : black;
    border-color: orange;
    border-radius: 10px;
}
.PasswordChange button.submit:hover, .ForgottenId button.submit:hover {
    background-color: orange;
    border-color: black;
    color: black;
}

@media  (min-width: 601px) {
    .PasswordChange input, .ForgottenId input {
        font-size: 1.5em;
        width : 100%;
    }
}

@media  (max-width: 600px) {
    .PasswordChange input, .ForgottenId input {
        width : 90%;
    }
    .PasswordChange button.submit, .ForgottenId button.submit {
        display: block;
        font-size: 1.2em;
        height : 2rem; 
        width : 65%;
        margin: 0 auto;
    }
}