/* TITLES */

.Panel h2 {
    position: relative;
    text-align: center;
}
.Panel > h2 {
    margin-bottom: 0;
}
.Panel .Header h2 {
    font-size: 1.2em;
    font-family: 'CODE','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.Panel .Header img {
    padding : 0;
}
/* PASSWORD CHANGE */
.Panel div.profileButton {
    cursor: pointer;
    position: relative;
    display: block;
    margin-bottom: 5px;
    font-size: 0.8em;
    width : 300px;
    height: 1.5em;
    margin: 0 auto 6px auto;
    text-align: center;
}
.Panel div.profileButton:hover {
    color: lightgrey
}
.Panel div.profileButton > span {
    position: relative;
}
.Panel .profileButton img{
    position: absolute;
    height : 1.4em;
    filter : invert(1);
    left : -1.7em;
    top : -0.2em;
}
.Panel .profileButton:hover img {
    filter: invert(0.8)
}

/* */

div.Panel {
    width : 80vw;
    margin: 0 auto;
    margin: 10vh auto ;
    padding: 40px;
    background: rgba(0,0,0,.5);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    color: white;
}

.Panel select {
    color: orange;
    background-color: black;
    border: solid 1px #ff7221;
    border-radius: 20px;
    width : 90%;
    margin: 0 auto;
    display: block;
    height : 3em;
    text-align: center;
    font-weight: bold;
    transition: all 0.3s;
    cursor: pointer;
    
}
.Panel select:hover {
    background-color: #ff7221;
    color: black;
}
.Panel .admin {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px #ff7221;
}
.Panel .content div.buttons {
    text-align: center;
}
.Panel .content button {
    position: relative;
    color: #ff7221;
    background-color: black;
    border: solid 1px #ff7221;
    font-size: 1.4em;
    padding: 3px 15px 1px 15px;
    width : 200px;
    transition: all 0.3s;
    font-family: 'CODE','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    cursor: pointer;
}
.Panel .content button:hover {
    background-color: #ff7221;
    color: black;
}
.Panel .content button.selected {
    background-color: #ff7221;
    color: black;
    border: solid 1px white;
}

.Panel .content button.left {
    border-radius: 20px 0 0 20px;
    margin-right : 2px;
}
.Panel .content button.right {
    border-radius: 0 20px 20px 0;
    margin-left: 2px;

}

.Panel .buttons button img {
    position: absolute;
    height : 1em;
    margin-top: 3px;
}
.Panel .buttons button.selected img {
    height: 1.05em;
}
.Panel .buttons button.left img {
    right : 15px;
}
.Panel .buttons button.right img {
    left : 15px;

}
.Panel div.years {
    margin-top : 12px;
}
.Panel div.years button{
    width: 4em;
}

@media only screen and (max-width: 600px) {
    div.Panel {
        width : 100vw;
    }
    .Panel .buttons button {
        width : 120px !important;
        height: 40px;
        font-size: 15px !important;
        padding : 0 !important;
    }
    .Panel .buttons button img {
        display: none;
    }
    .Panel div.years button{
        width: 5em;
    }
}


/* INSIDE THE MODAL */

.Panel .Modal div.logout {
    width : 85%;
    margin: 0 auto;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: solid 1px rgb(207, 207, 207);
}

.Panel .Modal div.logout button {
    cursor:pointer;
    background: none;
    color : #ff7221;
    border: none !important;
    text-align: end;
    /* width : 90%; */
}
.Panel .Modal div.logout button:hover {
    font-weight: bold;
}